import React from 'react';
import '../css/AboutPage.css';
import Footer from '../components/Footer';

const AboutPage = () => {
  return (
    <div className='flex flex-col bg-white items-center h-screen'>
      <div className='flex flex-col md:flex-row items-center justify-center gap-10 w-full px-10 mt-2' style={{ paddingTop: '80px' }}>
        <div className="flex flex-col items-center justify-center w-full md:w-1/2 gap-4">
          <div className="text-[12px] md:text-2xl font-semibold tracking-tight">
            欢迎使用！
          </div>
          {/* <img src={require("../images/support-me.jpeg")} alt="About Us" className="w-[10vw] h-auto block" /> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutPage;